import { useEffect, useState } from "react"

import { useDebouncedCallback } from "use-debounce"

import { SearchField } from "src/ui/Input/SearchField"

const DEFAULT_DELAY = 200 // ms

interface IDebouncedSearchField {
  onChange: (value: string) => void
  initialValue?: string
  label?: string
  delay?: number
  minWidth?: number
  fullWidth?: boolean
  size?: "small" | "medium"
  disabled?: boolean
}

// Enhancements:
// - Optionally show loading indicator

/**
 * Filter component that reports a changed value after a delay; useful for
 * debouncing local search results.
 */
// eslint-disable-next-line import/no-default-export
export default function SearchFilter({
  onChange,
  label = "",
  initialValue = "",
  delay = DEFAULT_DELAY,
  minWidth,
  disabled,
  ...props
}: IDebouncedSearchField) {
  const [text, setText] = useState(initialValue)
  useEffect(() => {
    // This effect is to let the parent control display text
    setText(initialValue)
  }, [initialValue])

  const debouncedCallback = useDebouncedCallback((s: string) => {
    onChange(s)
  }, delay)

  const setValue = (s: string) => {
    setText(s)
    debouncedCallback(s)
  }

  const clearValue = () => {
    // We call onChange('') here to instantly clear the search. However, in
    // order to not run into race conditions with debounce, we also call the
    // debounced function, so that the 'clear' action is queued up properly.
    onChange("")
    setValue("")
  }

  return (
    <SearchField
      placeholder={label}
      value={text}
      onChange={setValue}
      onClear={clearValue}
      minWidth={minWidth}
      disabled={disabled}
      {...props}
    />
  )
}
